<template>
    <div class="mypage_curation_send_wrap">
        <!--       의뢰 내역 리스트 레이아웃-->
        <table class="my_history_table" width="100%"  border="0" cellspacing="0" cellpadding="0"
               style = "table-layout: auto; width: 1200px; table-layout: fixed;">
            <colgroup>
                <col width="58px" />
                <col width="143px" />
                <col width="140px" />
                <col width="140px" />
                <col width="285px" />
                <col width="130px" />
                <col width="155px" />
                <col width="140px" />
            </colgroup>
            <tr class="title_row">
                <th colspan="1">No</th>
                <th colspan="1">{{$t('request_num')}}</th>
                <th colspan="1">{{$t('image')}}</th>
                <th colspan="1">{{$t('subject')}}</th>
                <th colspan="1">{{$t('detection_domain')}}</th>
                <th colspan="1">{{$t('request_date')}}</th>
                <th colspan="1">{{$t('compensation_unit')}}</th>
                <th colspan="1">{{$t('status')}}</th>
            </tr>
            <tr class="cursor" v-for="(detection, index) in detectionData" :key="`detection${detection.l_idx}`" @click="moveDetail(`/detection/request/${detection.l_idx}`)">
                <td>{{tableIdx(index)}}</td>
                <td>{{detection.l_uuid}}</td>
                <td>
                    <img :src="returnImageOption(detection.Item)" width="78" height="49" alt="" >
                </td>
                <td class="t_subject">{{detection.Item.i_name}}</td>
                <td>{{detection.dr_domain}}</td>
                <td>{{returnDateFormat(detection.created_at, 'YYYY.MM.DD')}}</td>
                <td>{{numFormat(detection.l_price)}}</td>
                <td>
                    {{returnStatusName(detection.l_status)}}
                </td>
            </tr>
            <!--     아래는 퍼블용 입니다! -->
<!--            <tr style="cursor: pointer" v-for="(detection, index) in detectionData" :key="`detection${detection.l_idx}`" @click="moveDetail(`/detection/request/${detection.l_idx}`)">
                <td>{{tableIdx(index)}}</td>  &lt;!&ndash; No &ndash;&gt;
                <td>E99b10ac-270</td>
                <td><img :src="returnImageOption(detection.Item)" width="78" height="49"/></td>
                <td class="t_subject">{{ tempSubject }}</td>  &lt;!&ndash; 아이템명 &ndash;&gt;

                <td>https://www.chocolatebars.com</td>  &lt;!&ndash; 검출도메인 &ndash;&gt;

                <td>{{returnDateFormat(tempDate, 'YYYY.MM.DD')}}</td>

                <td>700,000</td> &lt;!&ndash; 보상금(원) 비용 &ndash;&gt;

                <td v-if="detectionLayout === 0 || detectionLayout === 1">{{$t('request')}}</td>  &lt;!&ndash; 상태 &ndash;&gt;
                <td v-else-if="detectionLayout === 2">{{$t('de_review')}}</td>
                <td v-else-if="detectionLayout === 6 || detectionLayout === 5">{{$t('de_ready')}}</td>
                <td v-else-if="detectionLayout === 7">{{$t('de_process')}}</td>
                <td v-else-if="detectionLayout === 8">{{$t('de_closing')}}</td>
                <td v-else-if="detectionLayout === 9">{{$t('de_civil_criminal')}}</td>
                <td v-else>{{$t('cancel_s_request')}}</td>  &lt;!&ndash; 상태 &ndash;&gt;
            </tr>-->
        </table>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import {detectionStatus} from "@/service/detectionService";

export default {
    name: "DetectionListRequestHistoryLayout",
    mixins: [imageOption, dateMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        detectionData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
            detectionLayouts: 10,
            tempSubject: '아이템 제목이 길어지면 말줄임표를 합니다',
            tempDate: '20210123',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        },
        reverseKeys(n) {
            return [...Array(n).keys()].slice().reverse()
        },
        cancelRequest() {
            this.createConfirm({
                title: '',
                content: " '<span class='subject'>"+ this.tempSubject + "</span>'<br>" + this.$t('mypage_cancel_confirm'),
                confirmText : this.$t('yes'),
                cancelText : this.$t('cancel'),
                confirm: () => {

                },
                cancel: () => {

                }
            })
        },
        moveDetail(url) {
            this.$router.push(`${url}`);
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        returnImageOption(item) {
            if(util.isEmpty(item) || util.isEmpty(item.ItemFile) || util.isEmpty(item.ItemFile.Item)) {
                return '';
            }
            return `${item.ItemFile.Item[0].org_url}?${this.setImageOptions(78, 49, 'jpg')}`;
        },
        numFormat(num) {
            return util.isEmpty(num) || isNaN(num) ? '-' : util.Number.numFormat(num);
        },
        returnStatusName(status) {
            let index = detectionStatus.findIndex(ds => ds.value == status);
            return index > -1 ? this.$t(detectionStatus[index].name) : '';
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
